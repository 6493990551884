<template>
  <div class="vld-parent cyb-login">
    <login-form @sendSubmit="handleSubmit">
      <template #misc-content> </template>
      <template #form>
        <Card class="p-fluid">
          <template #header><h1 class="active">{{ $t('changepassword.title') }}</h1></template>
          <template #content>
            <div class="p-fluid">
              <div class="p-field">
                <Password
                  id="currentPassword"
                  v-model="currentPassword"
                  toggleMask
                  :placeholder="$t('changepassword.currentPassword')"
                  :feedback="false"
                >
                </Password>
                <field-error :value="v$.currentPassword" />
              </div>
            </div>

            <div class="p-fluid">
              <div class="p-field">
                <PasswordComplexity
                  id="newPassword"
                  v-model="newPassword"
                  toggleMask
                  :placeholder="$t('changepassword.newPassword')"
                  :feedback="true"
                >
                </PasswordComplexity>
                <field-error :value="v$.newPassword" />
              </div>
            </div>
            <div class="fluid">
              <div class="p-field">
                <Password
                  id="passwordConfirmation"
                  v-model="passwordConfirmation"
                  toggleMask
                  :feedback="false"
                  :placeholder="$t('common.verify_password')"
                />
                <field-error :value="v$.passwordConfirmation" />
              </div>
            </div>
            <div class="p-mb-3">
              <div class="recaptchaContainer">
                  <vue-recaptcha v-if="showRecaptcha" :siteKey="reCaptchaSiteKey" theme="light" :size="(compactCaptcha) ? 'compact' : 'normal'"
                  @verify="reCaptchaVerified" @expire="reCaptchaExpired" @fail="reCaptchaFailed" ref="vueRecaptcha"></vue-recaptcha>
              </div>
            </div>
          </template>
          <template #footer>
            <Button type="submit" :label="$t('changepassword.changeButton')" :disabled="!confirmed" />
          </template>
        </Card>
      </template>
    </login-form>
  </div>
  <CommonDialog ref="dialog"></CommonDialog>
</template>

<script>
import { adminService } from '../services/admin.service';
import { ErrorHelper } from '../helpers/ErrorHelper';
import { useVuelidate } from '@vuelidate/core';
import { sameAs, required, and, requiredIf } from '@vuelidate/validators';
import PasswordComplexity from '../components/PasswordComplexity.vue';
import CommonDialog from '../components/CommonDialog.vue';
import LoginForm from '../components/LoginForm.vue';
import FieldError from '../components/FieldError.vue';
import { authService } from '../services/auth.service';
import vueRecaptcha from 'vue3-recaptcha2';
import { apiConfig } from '../config/backend-api';

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    PasswordComplexity,
    CommonDialog,
    LoginForm,
    FieldError,
    vueRecaptcha
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      passwordConfirmation: '',
      showRecaptcha: false,
      isNotARobot: false
    };
  },
  computed: {
    compactCaptcha() {
      return window.outerWidth < 500;
    },
    confirmed() {
      return this.isNotARobot || !this.showRecaptcha;
    }
  },
  mounted() {
    this.reCaptchaSiteKey = apiConfig.reCaptchaSiteKey;
    this.showRecaptcha = !!this.reCaptchaSiteKey;
  },
  validations() {
    const validPassword = (value) => this.$store.getters.checkPasswordComplexity(value);
    return {
      currentPassword: { required },
      newPassword: {
        required: and(
          validPassword,
          requiredIf(function() {
            return this.newPassword !== '';
          })
        ),
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs(this.newPassword),
      },
    };
  },
  methods: {
    handleSubmit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        if (!this.$store.state.auth.user.role === authService.userRole.USER ) {
          adminService
            .changePassword(this.currentPassword, this.newPassword)
            .then(() => {
              this.showMessage(this.$t('changepassword.message'), () => { this.$router.push({ name: 'login' }) });
            })
            .catch((error) => {
              this.showError(ErrorHelper.getErrorMessage(error));
            });
        }
      }
    },
    reCaptchaVerified() {
      this.isNotARobot = true;
    },
    reCaptchaInvalidate() {
      this.$refs.vueRecaptcha.reset();
      this.isNotARobot = false;
    },
    reCaptchaExpired() {
      this.reCaptchaInvalidate();
    },
    reCaptchaFailed() {
      this.reCaptchaInvalidate();
    },
    showMessage(msg, callback) {
      this.$refs.dialog.show({
        title: this.$t('successDialog.title'),
        message: msg,
        icon: 'thin-check',
      }).then(() => {
        if (callback) callback();
      });
    },
    showError(errMsg, callback) {
      this.$refs.dialog.show({
        title: this.$t('errorDialog.title'),
        message: errMsg,
        icon: 'thin-cancel error',
      }).then(() => {
        if (callback) callback();
      });
    }
  }
};
</script>